.dark-theme {
  background-color: #111111;
  color: #ffffff;
  text-align: justify;
  word-wrap: break-word;
}
.light-theme {
  background-color: #20A84E;
  color: #ffffff;
  text-align: justify;
  word-wrap: break-word;
}
.white-theme {
  background-color: #ffffff;
  color: #111111;
  text-align: justify;
  word-wrap: break-word;
}
.text-align-center {
  text-align: center;
}
.display-none {
  display: none;
}
article {
  padding: 2rem;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
table.center-table {
  margin-left:auto;
  margin-right:auto;
}
table thead {
  background: #808080;
  font-weight: bold;
}
table th, table td {
  padding: 11px;
  border: 1px solid #6f6f6f;
}
h1 {
  text-align: center;
}